import React, { Component } from "react";
import { graphql } from "gatsby";
import "../css/rahul-styles.css";

import PopupForm from "../components/popupForm";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Sharing from "./../components/Sharing";
import TableOfContent from "./../components/TableOfContent";
class StudyResource extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
            programUrl: props.path
		};
	}
	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};

	render() {
		var filteredRes = [];
		const post = this.props.data.wordpressWpBlog;
		const metatitle = post.acf.meta_title ;
		const metadescription = post.acf.meta_description; 
	const focus_keyphrase = post.acf.focus_keyphrase; 
	const canonical_url = `https://theworldgrad.com${this.props.path}`; 
		this.props.data.wordpressWpBlog.blog_categories.forEach((element) => {
			this.props.data.allWordpressWpBlog.edges.forEach((data) => {
				if (filteredRes.length < 4) {
					if (data.node.blog_categories.includes(element) && filteredRes.includes(data) == false) filteredRes.push(data);
				}
			});
		});

		const author = post.author;

		const authorName =author.name;
		const authorBio = author.description;
		const authorPic = post.author && post.author.acf && post.author.acf.profile_picture ? post.author.acf.profile_picture.source_url : "https://assets.theworldgrad.com/wp-content/uploads/2024/03/picture-profile-icon-male-icon-human-or-people-sign-and-symbol-vector.jpg";



	 const wordsPerMinute = 200;
	 const wordCount = post.acf.content ? post.acf.content.split(/\s+/).length : 0;
	 const calculatedMinutes = Math.ceil(wordCount / wordsPerMinute)+1;
	 const calculatedReadingTime = `${calculatedMinutes} ${calculatedMinutes === 1 ? "minute" : "minutes"}`;
	 const readingTime =  calculatedReadingTime;
	 const pageURL =  `https://theworldgrad.com${this.state.programUrl}`;


const imageUrls = [];


const extractImageUrls = (content) => {
	const imgRegex = /<img[^>]+src=['"]([^'">]+)['"]/gi;
	const imageUrls = [];
	let match;
	
	if (post.featured_media && post.featured_media.source_url) {
		imageUrls.push(post.featured_media.source_url);
	  }


	while ((match = imgRegex.exec(content)) !== null) {
	  const url = match[1];
	  if (!imageUrls.includes(url)) {
		imageUrls.push(url);
	  }
	}
	return imageUrls;
  };

  const extractedImageUrls = extractImageUrls(post.acf.content);

  function formatDate(date, ISO = false) {

	if (!(date instanceof Date)) {
	  date = new Date(date);
	}
  

  

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	const hours = String(date.getHours()).padStart(2, '0');
	const minutes = String(date.getMinutes()).padStart(2, '0');
	const seconds = String(date.getSeconds()).padStart(2, '0');
	const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  

	if (ISO) {
	  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
	} else {
	  return `${day}-${month}-${year}`;
	}
  }



	 const schema = `{
		"@context": "https://schema.org",
		"@type": "BlogPosting",
		"mainEntityOfPage": {
		  "@type": "WebPage",
		  "@id": "${pageURL}"
		},
		"headline": "${post.title}",
		"name": "${post.title}",
 "image": ${JSON.stringify(extractedImageUrls)},
 
 
		"datePublished": "${formatDate(post.date, true)}",  
		"dateModified": "${formatDate(post.modified, true)}",  
		"author": {
		  "@type": "Person",
		  "name": "${authorName || 'The WorldGrad'}",  
		  "url": "${authorPic || 'https://assets.theworldgrad.com/gatsby-assets/images/logo.png'}"  
		},
		"publisher": {
		  "@type": "Organization",
		  "name": "The WorldGrad",
		  "logo": {
			"@type": "ImageObject",
			"url": "https://assets.theworldgrad.com/gatsby-assets/images/logo.png" 
		  }
		},
		"hasPart": []
	  }
	  `;
	
		return (
			<div>
				<Layout>
					<Helmet>
					<title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
		  <script type="application/ld+json">
                        
                        {JSON.stringify(JSON.parse(schema))}
                    
                    </script>





          


					</Helmet>

					<PopupForm time={this.state.time} />
					<div className="container newStyle">
						<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
							<li class="breadcrumb-item">
								<a href="/">Home</a>
							</li>
							<li class="breadcrumb-item">
								<a href="/study-resources/">Study Resources</a>
							</li>
							<li class="breadcrumb-item active">{ReactHtmlParser(post.title)}</li>
						</ol>
					</div>
					<div className="container-fluid blog-detail-container">
						<div className="row blog-detail-main-heading-text-row">
							{post.featured_media != null ? (
								<div className="col-12">
									<div className="blog-banner-img">
										<img src={post.featured_media.source_url} className="mb-3" alt={ReactHtmlParser(post.title)} />
									</div>
								</div>
							) : (
								""
							)}
							<div className="col-md-6 col-lg-10">
								<h1 className="blog-detail-main-heading-text">{ReactHtmlParser(post.title)}</h1>
								<div className="my-1 d-flex flex-wrap">
									<span className="blog-detail-sub-heading">
										Reading Time <strong>{readingTime  || "0"}</strong>
									</span>
									<span className="mx-3 text-muted">/</span>
									<span className="blog-detail-sub-heading">
						
									{post.blog_categories.includes(21) ? (
										<span>
										Published on <strong>{formatDate(post.date)}</strong>
									  </span>
  
) : (
	<span>
    Updated on <strong>{post.modified ? formatDate(post.modified) : formatDate(post.date)}</strong>
  </span>
)}
</span>
								</div>
								<div className="social-share-wrapper mt-3">
									<Sharing />
								</div>
							
							</div>
						</div>
						<div className="row mx-0">
							<div className="card shadow w-100 blog-detail-main-card">
						
								<div className="row blog-detail-card-content mt-10 align-items-start">
									<div className="col-md-8 blog-detail-topic-body">
										<p className="blog-detail-topic-para-text">{ReactHtmlParser(post.acf.description)}</p>
										<TableOfContent />
										<p className="blog-detail-topic-para-text">{ReactHtmlParser(post.acf.content)}</p>
										
						<div class="container"	>					
<div class="row author-box">
<div className="col-md-2  author-image">
 <img 
        src={authorPic}
        alt={authorName}  
    /><br/>
										
			

</div>
<div className="col-md-10  author-description">

<p class=" font-weight-600 font-size-18 mb-2">Author: <span class="color-blue">{authorName}</span></p>
									<p>{authorBio}</p>
								</div>


</div>

									</div>

		</div>
	
		
	

									<div className="col-md-4 blog-detail-sidebar-wrapper">
										<div className="row blog-detail-side-menu">
											<div className="col-sm-6 col-md-12 mb-4">
												<div className="card shadow blog-detail-similar-blogs-card">
													<p className="blog-detail-similar-blogs"> Similar Blogs </p>
													{filteredRes.map(({ node }, index) => {
														return (
															<div>
																<a href={`/study-resources/${node.slug}/`}>
																	<p className="blog-detail-similar-blogs-title"> {ReactHtmlParser(node.title)} </p>
																</a>
																<p className="blog-detail-similar-blogs-posted-date">
																{node.blog_categories.includes(21) ? (
										<span>
										Published on  {node.date}
									  </span>
  
) : (
	<span>
    Updated on {node.modified ? node.modified : node.date}
  </span>
)}</p>
																{index != filteredRes.length - 1 && <hr style={{ color: "#626E81" }} />}
															</div>
														);
													})}

													
												</div>
											</div>
											
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				
				</Layout>
			</div>
		);
	}
}

export default StudyResource;
export const WorldgradBlogQuery = graphql`
	query currentWorldgradBlogQuery($id: String!) {
		wordpressWpBlog(id: { eq: $id }) {
			id
			title
			slug
			blog_categories
		 date(formatString: "YYYY-MM-DDTHH:mm:ss.sss")
  modified(formatString: "YYYY-MM-DDTHH:mm:ss.sss")
			featured_media {
				source_url
			}
			acf {
		
				description
				meta_title
meta_description
focus_keyphrase
canonical_url

				content
				dynamic_schema
			}
			author {
				description
				name
				acf {
				  profile_picture {
					source_url
				  }
				}
			  }
			}
		allWordpressWpBlog {
			edges {
				node {
					id
					title
					slug
					blog_categories
					date(formatString: "DD-M-YYYY")
					modified(formatString: "DD-M-YYYY")
				}
			}
		}
	}
`;


